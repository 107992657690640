import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Jobtask } from '../models/jobtask.model';

@Injectable()
export class JobtaskService {
  private jobtasksUrl: string = 'api/jobtasks';

    constructor(
      private http: HttpClient) { }

  getActiveJobtask(): Observable<Jobtask> {
    //jp.employee_id = this.localStorage.getDataObject("employee_id");
    //jp.lang = this.localStorage.getDataObject("language");
    //jp.site = this.localStorage.getDataObject("sitename");
    return this.http.get<Jobtask>(this.jobtasksUrl);
  }

  getjobtasks(typ: number): Observable<Jobtask[]> {
    //jp.employee_id = this.localStorage.getDataObject("employee_id");
    //jp.site = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.jobtasksUrl}/${typ}`;
    return this.http.get<Jobtask[]>(endpointUrl);
	}

}

