<div>
  <p></p>
  <div class="form-group">
    <div class="row" align="center">
      <p><strong>{{personname}}</strong></p>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <my-date-picker name="startdate" [options]="myDatePickerOptions" [locale]="locale" [(ngModel)]="startDate" required #dateField="ngModel" (dateChanged)="onDateChanged($event)"></my-date-picker>
      </div>
      <div class="col-xs-6">
        <button class="btn" (click)="previousday()"><span class="fa fa-angle-left" aria-hidden="true"></span></button>
        <button class="btn" (click)="fetchstamps()"><strong>{{'common.GetStamps' | translate}}</strong></button>
        <button class="btn" (click)="nextday()"><span class="fa fa-angle-right" aria-hidden="true"></span></button>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-xs-2">
          <button class="btn" (click)="newRow()" [disabled]="!allowdate"><strong>{{'stampcorrection.NewStamp' | translate}}</strong></button>
        </div>
      </div>
    </div>
    <div class="form-group">
      <ngx-datatable class="material"
                     [columns]="columns"
                     [rows]="stampproposals"
                     [rowClass]="getRowClass"
                     [columnMode]="'force'"
                     [headerHeight]="30"
                     [messages]="{emptyMessage: '-----' | translate, selectedMessage: false}"
                     [rowHeight]="'auto'">
        <ngx-datatable-column name="{{'stampcorrection.BookingTime' | translate}}" [minWidth]="55">
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            {{row.bookingTime}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'stampcorrection.Direction' | translate}}" [minWidth]="50">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.dir}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'stampcorrection.Reasoncode' | translate}}" [minWidth]="45">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.reasonTitle}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'stampcorrection.Reasoncode2' | translate}}" [minWidth]="45">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.reason2Title}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'stampcorrection.Application' | translate}}" [minWidth]="10">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.flags}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'stampcorrection.Comment' | translate}}" [minWidth]="20">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.comment}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'common.Doer' | translate}}" [minWidth]="45">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.user}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="" sortable="false" prop="id" [minWidth]="45">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <button md-icon-button (click)="editRow(row)" style="color:black" *ngIf="row.stampType!= 4" [disabled]="row.deleted==true || row.stampType == 4">
              <i class="fa fa-edit"></i>
            </button>
            <button md-icon-button (click)="deleteRow(row)" style="color:black">
              <i class="fa fa-trash"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
    <div class="modal fade" bsModal #stampModal="bs-modal" (onHidden)="onEditorModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">{{editingStampName ? ('stampcorrection.EditStamp' | translate) : ('stampproposal.NewStamp' | translate)}}</h4>
            <button type="button" class="close pull-right" title="Close" (click)="stampModal.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <stampproposaldetail #stampEditor></stampproposaldetail>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
