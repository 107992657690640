<div>
  <p></p>
  <div class="form-group">
    <div class="row" align="center">
      <p><strong>{{personname}}</strong></p>
    </div>
    <div class="row">
      <div class="col-xs-7">
        <p><strong>{{'stampreport.StartDate' | translate}}</strong></p>
      </div>
      <div class="col-xs-5">
        <p><strong>{{'stampreport.EndDate' | translate}}</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-5">
        <my-date-picker name="startdate" [options]="myDatePickerOptions" [locale]="locale" [(ngModel)]="startDate" required #dateField="ngModel"></my-date-picker>
      </div>
      <div *ngIf="!mobileDevice">
        <div class="col-xs-1">
          <button class="btn" (click)="refresh()"><strong>{{'common.Refresh' | translate}}</strong></button>
        </div>
        <div class="col-xs-1">
          <button class="btn" (click)="printpdf()"><i class="fa fa-print"></i></button>
        </div>
      </div>
      <div *ngIf="mobileDevice">
        <div class="col-xs-2">
          <button class="btn" (click)="refresh()"><strong>{{'common.Refresh' | translate}}</strong></button>
        </div>
      </div>
      <div class="col-xs-5">
        <my-date-picker name="enddate" [options]="myDatePickerOptions" [locale]="locale" [(ngModel)]="endDate" required #dateField="ngModel"></my-date-picker>
      </div>
    </div>
  </div>
  <div class="form-group">
    <ngx-datatable class="material"
                   [rows]="stampreports"
                   [rowClass]="getRowClass"
                   [columnMode]="'force'"
                   [headerHeight]="30"
                   [rowHeight]="'auto'">
      <ngx-datatable-column name="{{'common.Date' | translate}}" [width]="60">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.bookingDate}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'common.WdName' | translate}}" [width]="20">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.wdayName}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'common.Time' | translate}}" [width]="60">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.bookingTime}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'common.Direction' | translate}}" [width]="10">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.bookingDir}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'common.Reason' | translate}}" [width]="30">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.bookingReason}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'common.EndSaldo' | translate}}" [width]="70">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.comment}}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
  <div class="form-group">
    <div class="row">
      <button class="btn" style="margin-left:25px;" (click)="summary()"><strong>{{'common.Acc' | translate}}</strong></button>
    </div>
    <div *ngIf="summaryreport.length>0">
      <ngx-datatable class="material"
                     [rows]="summaryreport"
                     [columnMode]="'force'"
                     [headerHeight]="30"
                     [rowHeight]="'auto'">
        <ngx-datatable-column name="{{'common.ReasonDescr' | translate}}" [width]="100">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.summaryReason}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'common.Time' | translate}}" [width]="60">
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            {{row.summaryTime}}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>

