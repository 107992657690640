<div class="container">
  <div class="form-group">
    <div class="row bg-grey">
      <h3>PremiTime</h3>
      <h4>{{'helps.ProTimeText' | translate}}</h4>
      <p>
        Version: 3.17 www.premisol.fi 27.8.2024
        <br />
        {{'helps.LicenseValid' | translate}}: {{expiredate}}
        <br />
      </p>
    </div>
  </div>
  <br />
  <div class="form-group" *ngIf="gUser">
    <div class="row">
      <input type="text" size="30" placeholder={{Passwd1}} [(ngModel)]="password1" name="password1">
    </div>
    <div class="row">
      <input type="text" size="30" placeholder={{Passwd2}} [(ngModel)]="password2" name="password2">
    </div>
    <div class="row">
      <textarea id="message" style="border: none" [(ngModel)]="message" name="message" rows="4" cols="30"></textarea>
    </div>
  </div>
  <br />
  <div class="form-group">
    <div class="form-inline">
      <button type="button" (click)="update()" class="btn btn-success">{{'helps.Update' | translate}}</button>
      <button type="button" (click)="close()" class="btn btn-primary"> {{'helps.Cancel' | translate}}</button>
    </div>
  </div>
</div>
