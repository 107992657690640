import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AppTranslationService } from "../services/app-translation.service";
import { AlertService } from '../services/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let errmess: string = "";
      if (err.status === 401)
        errmess = this.translationService.getTranslation('notifications.AutoLogout'); // auto logout if 401 response returned from api
      else if (err.status == 420)
        errmess = this.translationService.getTranslation('notifications.WrongUser');
      else if (err.status == 400)
        errmess = this.translationService.getTranslation('notifications.ServerError');
      else if (err.status == 404)
        errmess = this.translationService.getTranslation('notifications.NotFound');
      else if (err.status == 421)
        errmess = this.translationService.getTranslation('notifications.NoLicense');
      else if (err.status == 422)
        errmess = this.translationService.getTranslation('notifications.LicenseExpired');
      else if (err.status == 423)
        errmess = this.translationService.getTranslation('notifications.FeatureNotLicensed');
      else if (err.status == 424)
        errmess = this.translationService.getTranslation('notifications.NoUserRights');
      else if (err.status == 425)
        errmess = this.translationService.getTranslation('notifications.SystemNotDefined');
      else if (err.status == 426)
        errmess = this.translationService.getTranslation('notifications.MaxPersonsExceeded');
      else if (err.status == 427)
        errmess = this.translationService.getTranslation('notifications.NoDbConnection');
      else
        errmess = this.translationService.getTranslation('notifications.UndefinedError') + err.status;
      this.alertService.error(errmess, true);
      return throwError(errmess);
    }))
  }
}
