import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { StampCorrectionService } from '../../services/stampcorrection.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { AuthService } from '../../services/auth.service';
import { StampCorrection } from '../../models/stampcorrection.model';
import { AbsenceCodeService } from "../../services/absencecode.service";
import { BookingIdService } from "../../services/bookingid.service";
import { AbsenceCode } from '../../models/absencecode.model';
import { BookingId } from '../../models/bookingid.model';

@Component({
  selector: 'stampproposaldetail',
  templateUrl: './stampproposaldetail.component.html',
  styleUrls: ['./stampproposaldetail.component.css']
})

export class StampProposalDetail implements OnInit {

  public isEditMode = false;
  private isNewStamp = false;
  private isSaving = false;
  private showValidationErrors = false;
  private editingStamp: string;
  private stamp: StampCorrection = new StampCorrection();
  public stampEdit: StampCorrection = new StampCorrection();
  private startdate;
  public hasExtraData = false;
  public formResetToggle = true;
  absencecode: number;
  absencecodestr: string;
  rcodetype: number;
  secondcode: number;
  secondcodestr: string;
  bookingid: number;
  bookingidstr: string;
  absencecodes: AbsenceCode[] = [];
  bookingids: BookingId[] = [];
  clockTime = { hour: 8, minute: 0 };
  extraData: string;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @ViewChild('f', { static: true })
  private form;

  constructor(private alertService: AlertService,
    private stampCorrectionService: StampCorrectionService,
    private authService: AuthService,
    private absencecodeService: AbsenceCodeService,
    private bookingidService: BookingIdService,
    private translationService: AppTranslationService) { }

  ngOnInit(): void {
    //this.getAbsenceCodes();
    this.getBookingIds();
  }

  getAbsenceCodes(corrDate: string) {
    this.absencecodeService.getabsencecodes(0, corrDate)
      .subscribe(data => {
        this.absencecodes = data;
      },
        (error: any) => {
          //console.log(error);
        });
  }

  getBookingIds() {
    this.bookingidService.getbookingids()
      .subscribe(data => {
        this.bookingids = data;
      },
        (error: any) => {
          //console.log(error);
        });
  }

  selectedAbsenceCode = this.absencecodes[0];
  toNumber() {
    if (this.absencecodes.length > 1) {
      this.absencecode = this.selectedAbsenceCode.reasoncode_id;
      this.absencecodestr = this.selectedAbsenceCode.title;
    }
  }

  selectedSecondCode = this.absencecodes[0];
  toSecondNumber() {
    if (this.absencecodes.length > 1) {
      this.secondcode = this.selectedSecondCode.reasoncode_id;
      this.secondcodestr = this.selectedSecondCode.infotext;
    }
  }

  selectedBookingId = this.bookingids[0];
  toBookingId() {
    this.bookingid = this.selectedBookingId.booking_id;
    this.bookingidstr = this.selectedBookingId.descr;
  }

  compareFn(c1: BookingId, c2: BookingId): boolean {
    return c1 && c2 ? c1.booking_id === c2.booking_id : c1 === c2;
  }

  private edit() {
    if (!this.stampEdit)
      this.stampEdit = new StampCorrection();
    this.isEditMode = true;
    this.showValidationErrors = true;
  }

  public save() {
    if (!this.hasExtraData)
      this.stampEdit.extension_data = null;
    else {
      this.bookingid = 1;
      this.stampEdit.extension_data = this.extraData;
    }
    this.stampEdit.reason = this.absencecode;
    this.stampEdit.reasonTitle = this.absencecodestr;

    this.stampEdit.secondReason = this.secondcode;
    this.stampEdit.reason2Title = this.secondcodestr;

    if (this.bookingid != null) {
      this.stampEdit.bookingId = this.bookingid;
      this.stampEdit.dir = this.bookingidstr;
    }
    this.stampEdit.bookingTime = ("0" + this.clockTime.hour).slice(-2) + "." + ("0" + this.clockTime.minute).slice(-2)
    if (this.isNewStamp) {

      this.stampCorrectionService.newCorrectionStamp(this.stampEdit, "2")
        .subscribe(stamp => this.saveSuccessHelper(stamp),
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    }
    else {
      this.stampCorrectionService.updateCorrectionStamp(this.stampEdit, "2")
        .subscribe(response => this.saveSuccessHelper(),
          error => this.saveFailedHelper(error));
    }
  }

  private saveSuccessHelper(stamp?: StampCorrection) {
    if (stamp)
      Object.assign(this.stampEdit, stamp);

    this.isSaving = false;
    this.showValidationErrors = false;
    Object.assign(this.stamp, this.stampEdit);
    this.stampEdit = new StampCorrection();
    this.resetForm();
    //if (this.isNewStamp)
    //  this.alertService.success(this.translationService.getTranslation('notifications.CreateOk'),false);
    //else
    //  this.alertService.success(this.translationService.getTranslation('notifications.SaveOk'), true);
    this.isEditMode = false;

    if (this.changesSavedCallback)
      this.changesSavedCallback();
  }

  private saveFailedHelper(error: any) {
    this.alertService.error(this.translationService.getTranslation('notifications.SaveError'));
    if (this.changesFailedCallback)
      this.changesFailedCallback();
  }

  public cancel() {
    this.stampEdit = new StampCorrection();
    this.showValidationErrors = false;
    this.resetForm();
    if (this.changesCancelledCallback)
      this.changesCancelledCallback();
  }

  private close() {
    this.stampEdit = this.stamp = new StampCorrection();
    this.showValidationErrors = false;
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback)
      this.changesSavedCallback();
  }

  resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    }
    else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  newStamp(startDate: any, employeeId: string, abs: any) {
    this.hasExtraData = false;
    this.isNewStamp = true;
    this.editingStamp = null;
    this.absencecodes = abs;
    this.stamp = this.stampEdit = new StampCorrection();
    var d = startDate.date;
    this.stampEdit.bookingDate = ("0" + d.day).slice(-2) + "." + ("0" + d.month).slice(-2) + "." + d.year;
    var corrDate = d.year + "-" + ("0" + d.month).slice(-2) + "-" + ("0" + d.day).slice(-2);
    this.getAbsenceCodes(corrDate);
    //this.stampEdit.employee_id = employeeId;
    this.stampEdit.bookingId = 1;
    this.bookingid = 1;
    this.absencecode = null;
    this.absencecodestr = null;
    this.secondcode = null;
    this.secondcodestr = null;
    let hour = 8;
    let minute = 0;
    this.clockTime = { hour, minute };
    this.edit();
    return this.stampEdit;
  }

  editStamp(stamp: StampCorrection, startDate: any, abs: any) {
    this.startdate = startDate;
    this.absencecodes = abs;
    this.hasExtraData = stamp.reason != null && abs[abs.findIndex(x => x.reasoncode_id === stamp.reason)].withExtraData == 1;
    if (this.hasExtraData) {
      this.extraData = stamp.extension_data;
      this.rcodetype = stamp.reasonType;
    }
    if (stamp.reason > 0) {
      this.selectedAbsenceCode = new AbsenceCode();
      this.selectedAbsenceCode = abs[abs.findIndex(x => x.reasoncode_id === stamp.reason)];
    }
    if (stamp.secondReason > 0) {
      this.selectedSecondCode = new AbsenceCode();
      this.selectedSecondCode = abs[abs.findIndex(x => x.reasoncode_id === stamp.secondReason)];
    }
    let hour = Number(stamp.bookingTime.substring(0, 2));
    let minute = Number(stamp.bookingTime.substring(3, 5));
    this.clockTime = { hour, minute };
    this.bookingid = stamp.bookingId;
    this.absencecode = stamp.reason;
    this.absencecodestr = stamp.reasonTitle;
    this.secondcode = stamp.secondReason;
    this.secondcodestr = stamp.reason2Title;
    this.isNewStamp = false;
    this.editingStamp = stamp.bookingTime;
    this.stamp = new StampCorrection();
    this.stampEdit = new StampCorrection();
    Object.assign(this.stamp, stamp);
    Object.assign(this.stampEdit, stamp);
    this.edit();
    return this.stampEdit;
  }


}
