import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteService } from '../../services/site.service';
import { SetPassword } from '../../models/setpassword.model';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { AppTranslationService } from "../../services/app-translation.service";

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']

})
export class AboutComponent {
  sitename: string;
  expiredate: string;
  password1: string;
  password2: string;
  Passwd1: string;
  Passwd2: string;
  employeeId: string;
  comment: string;
  message: string;
  passwdStr: string;
  gUser: boolean;
  setPasswd = new(SetPassword);

  constructor(
    private siteService: SiteService,
    private localStorage: LocalStoreManager,
    private translationService: AppTranslationService,
    private router: Router
  ) {
  }

  private handleData(data: string) {
    let index = data.indexOf("ExpDate:");
    this.sitename = data.substr(0, index);
    this.expiredate = data.substr(index + 9, data.length);
  }

  ngOnInit() {
    this.Passwd1 = this.translationService.getTranslation('helps.Passwd1');
    this.Passwd2 = this.translationService.getTranslation('helps.Passwd2');
    this.gUser = this.localStorage.getDataObject("guser");
    this.siteService.getSystemData()
      .subscribe(data => {
        this.handleData(data)
      });
  }

  private handlePassword(data: string) {
    this.message = data;
  }

  public update() {
    //this.setPasswd.employeeid = this.localStorage.getDataObject("employee_id");
    this.setPasswd.oldpasswd = this.password1;
    this.setPasswd.newpasswd = this.password2;
    this.siteService.updatePassword(this.setPasswd)
      .subscribe(data => {
        this.handlePassword(data)
      });
  }

  public close() {
    if (this.localStorage.getDataObject("rights") != 1)
      this.router.navigate(['/realtimestamp']);
    else
      this.router.navigate(['/stampreport']);
  }
}
