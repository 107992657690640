import { NgModule, LOCALE_ID, ErrorHandler } from "@angular/core";
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common'

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { registerLocaleData } from '@angular/common';
import localeFi from '@angular/common/locales/fi';
registerLocaleData(localeFi);
import localeSv from '@angular/common/locales/sv';
registerLocaleData(localeSv);
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { AlertService } from './services/alert.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { RealTimeStampService } from './services/realtimestamp.service';
import { AbsenceCodeService } from './services/absencecode.service';
import { JobtaskService } from "./services/jobtask.service";
import { StampReportService } from './services/stampreport.service';
import { StampCorrectionService } from './services/stampcorrection.service';
import { BookingIdService } from './services/bookingid.service';
import { SiteService } from './services/site.service';
import { DataService } from './services/data.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { GeoLocationService } from './services/geo-location.service';
import { DailyProgramService } from './services/dailyprogram.service';
import { CircAddsService } from './services/circadds.service';


import { JwtInterceptor } from './providers/jwt.interceptor';
import { ErrorInterceptor } from './providers/error.interceptor';

import { AppComponent } from "./components/app.component";
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { AboutComponent } from "./components/about/about.component";
import { AlertComponent } from './components/alert/alert.component';
import { RealTimeStampComponent } from './components/realtimestamp/realtimestamp.component';
import { ReturnTimeComponent } from './components/returntime/returntime.component';
import { JobNumberComponent } from './components/jobnumber/jobnumber.component';
import { AdvanceBookingComponent } from './components/advancebooking/advancebooking.component';
import { StampReportComponent } from './components/stampreport/stampreport.component';
import { StampCorrectionComponent } from './components/stampcorrection/stampcorrection.component';
import { StampCorrectionDetail } from './components/stampcorrection/stampcorrectiondetail.component';
import { StampProposalComponent } from './components/stampproposal/stampproposal.component';
import { StampProposalDetail } from './components/stampproposal/stampproposaldetail.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { LocalizedDatePipe } from './pipes/localizedDate.pipe';
import { MyDatePickerModule } from 'mydatepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    AppRoutingModule,
    MyDatePickerModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    NgxDatatableModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot()
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    AboutComponent,
    RealTimeStampComponent,
    ReturnTimeComponent,
    JobNumberComponent,
    AdvanceBookingComponent,
    StampReportComponent,
    StampCorrectionComponent,
    StampCorrectionDetail,
    StampProposalComponent,
    StampProposalDetail,
    AlertComponent,
    HomeComponent,
    LocalizedDatePipe,
    CapitalizePipe
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: LOCALE_ID, useValue: "fi" }, //replace "en-US" with your locale
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy },
    AlertService,
    AppTranslationService,
    LocalStoreManager,
    StampReportService,
    StampCorrectionService,
    BookingIdService,
    SiteService,
    DataService,
    DatePipe,
    RealTimeStampService,
    JobtaskService,
    GeoLocationService,
    DailyProgramService,
    CircAddsService,
    AbsenceCodeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
