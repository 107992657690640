// ======================================
// Main Angular component 
import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChildren, AfterViewInit, QueryList } from "@angular/core";
import { Router, NavigationStart } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, AlertDialog, DialogType } from '../services/alert.service';
import { AppTranslationService } from "../services/app-translation.service";
import { LocalStoreManager } from '../services/local-store-manager.service';
import { AuthService } from '../services/auth.service';
import { LoginComponent } from "../components/login/login.component";

var alertify: any = require('../assets/scripts/alertify.js');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {

  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  enableCorrstamp: boolean;
  shouldShowLoginModal: boolean;
  appTitle = "PremiTime";
  siteTitle = "";
  @ViewChildren('loginModal,loginControl')
  modalLoginControls: QueryList<any>;
  loginModal: ModalDirective;
  loginControl: LoginComponent;
  private _subscription;

  constructor(
    private localStorage: LocalStoreManager,
    private alertService: AlertService,
    private authService: AuthService, private translationService: AppTranslationService,
    public router: Router
  ) {
    localStorage.initialiseStorageSyncListener();
    this.shouldShowLoginModal = false;
    translationService.addLanguages(["en", "fi", "sv"]);
    this.translationService.setDefaultLanguage("fi");
    this.siteTitle = authService.systemName;
    this._subscription = authService.systemNameChange.subscribe((value) => { this.siteTitle = value; });
  }

  ngAfterViewInit() {

    this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
      controls.forEach(control => {
        if (control) {
          if (control instanceof LoginComponent) {
            this.loginControl = control;
            this.loginControl.modalClosedCallback = () => this.loginModal.hide();
          }
          else {
            this.loginModal = control;
            this.loginModal.show();
          }
        }
      });
    });
  }

  about() {
    if (this.isUserLoggedIn)
      this.router.navigate(['/about']);
  }

  logout() {
    this.authService.logout()
  }

  ngOnInit() {
    this.isUserLoggedIn = this.authService.isLoggedIn;
    this.enableCorrstamp = this.localStorage.getDataObject("enablecorrstamp");
    this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));

    this.authService.reLoginDelegate = () => this.shouldShowLoginModal = true;
    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;
    });
    this.siteTitle = this.localStorage.getDataObject("sitename");
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let url = (<NavigationStart>event).url;

        if (url !== url.toLowerCase()) {
          this.router.navigateByUrl((<NavigationStart>event).url.toLowerCase());
        }
      }
    });
  }


  ngOnDestroy() {
    //prevent memory leak when component destroyed
    this._subscription.unsubscribe();
  }

  get canViewStampCorrection() {
    return (this.localStorage.getDataObject("enablecorrstamp") && (this.localStorage.getDataObject("rights") != 1));
  }

  get canViewStampProposal() {
    return (!this.localStorage.getDataObject("enablecorrstamp") && (this.localStorage.getDataObject("rights") != 1));
  }

  onLoginModalHidden() {
    this.shouldShowLoginModal = false;
  }

  getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  }

  get canViewStamp() {
    return (this.localStorage.getDataObject("rights") != 1);
  }

  showDialog(dialog: AlertDialog) {

    alertify.set({
      labels: {
        ok: dialog.okLabel || "OK",
        cancel: dialog.cancelLabel || this.translationService.getTranslation('common.Cancel')
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);

        break
      case DialogType.confirm:
        alertify
          .confirm(dialog.message, (e) => {
            if (e) {
              dialog.okCallback();
            }
            else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          });

        break;
      case DialogType.prompt:
        alertify
          .prompt(dialog.message, (e, val) => {
            if (e) {
              dialog.okCallback(val);
            }
            else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          }, dialog.defaultValue);

        break;
    }
  }
}

