import { Component, OnInit, Input } from "@angular/core";
import { AlertService } from '../../services/alert.service';
import { AuthService } from "../../services/auth.service";
import { SiteService } from '../../services/site.service';
import { UserLogin } from '../../models/user-login.model';
import { EsmiSite } from '../../models/esmisite.model';
import { AppTranslationService } from "../../services/app-translation.service";
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: "app-login",
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  sites: EsmiSite[] = [];
  site: EsmiSite;
  siteName: string;
  model: any = {};
  userLogin = new UserLogin();
  isSystem = false;
  returnUrl: string;
  formResetToggle = true;
  loginStatusSubscription: any;
  modalClosedCallback: () => void;
  public manySites = false;
  @Input()
  isModal = false;

  constructor(
    public dataservice: DataService,
    private authService: AuthService,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private siteService: SiteService,
    private localStorage: LocalStoreManager) {
  }

  ngOnInit() {
    this.loadSites();
    if (this.localStorage.getDataObject("username"))
      this.userLogin.username = this.localStorage.getDataObject("username");
    if (this.localStorage.getDataObject("password"))
      this.userLogin.password = this.localStorage.getDataObject("password");
    if (this.localStorage.getDataObject("rememberme"))
      this.userLogin.rememberMe = this.localStorage.getDataObject("rememberme");
    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    }
    else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
        if (this.getShouldRedirect()) {
          this.authService.redirectLoginUser();
        }
      });
    }
    this.dataservice.stampData = null;
    this.dataservice.realStamp = null; 
  }

  loadSites(): void {
     this.siteService.getSites()
       .subscribe(results => {
         if (results != null) {
           this.sites = results as EsmiSite[];
           this.manySites = this.sites.length > 1;
           this.site = results[0];
           this.siteName = this.site.siteName;
           this.translationService.setDefaultLanguage(this.site.siteLang);
           this.isSystem = true;
         }
         else {
           this.alertService.error(this.translationService.getTranslation('notifications.SystemNotDefined'));
           this.isSystem = false;
         }
       },
         error => {
         });
  }

  showErrorAlert(num: number) {
    if (num == 1)
      this.alertService.error(this.translationService.getTranslation('login.UsernameRequired'));
    else
      this.alertService.error(this.translationService.getTranslation('login.PasswordRequired'));
  }

  valueChange() {
    this.siteName = this.site.siteName;
  }

  login() {
    this.userLogin.sitename = this.siteName;
    this.authService.login(this.userLogin)
      .subscribe(results => {
        this.authService.store_userdata(results);
        this.authService.systemName = (this.localStorage.getDataObject("sitename"));
        this.authService.systemNameChange.next(this.authService.systemName);
          this.translationService.changeLanguage(this.localStorage.getDataObject("language"));
          //this.startTimer();
          //this.router.navigate([this.returnUrl]);
        },
        error => {
          this.alertService.error(error);
      });

  }

  getShouldRedirect() {
    return !this.isModal && this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }
}
