import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from '../services/local-store-manager.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private localStorage: LocalStoreManager) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let access_token = this.localStorage.getDataObject("id_token");
    if (access_token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + access_token 
        }
      });
    }

    return next.handle(request);
  }
}
