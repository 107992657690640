import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable()
export class AuthGuard implements CanActivate {
  rights: number;

  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorage: LocalStoreManager) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url);
  }
  checkLogin(url: string): boolean {

    if (this.authService.isLoggedIn) {
      this.authService.loginRedirectUrl = url;
      if (url == "/about") {
        this.router.navigate(['/about']);
      }
      else {
        this.rights = this.localStorage.getDataObject("rights");
        if (this.rights == 1)
          this.router.navigate(['/stampreport']);
        else
          this.router.navigate(['/realtimestamp']);
      }
      return true;
    }
    this.authService.redirectLogoutUser();
    return false;
  }

}
