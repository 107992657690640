import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
declare type Position = any; // or a more specific type definition

@Injectable()
export class GeoLocationService {
  public getLocation(): Observable<Position> {
    return new Observable(observer => {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            observer.next(position);
            observer.complete();
          },
          (error) => observer.error(error)
        );
      } else {
        observer.error('Unsupported browser');
      }
    });
  }
}
