import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthService } from '../../services/auth.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { StampCorrectionService } from "../../services/stampcorrection.service";
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import { StampCorrection } from '../../models/stampcorrection.model';
import { ReportParam } from '../../models/reportparam.model';
import { Person } from '../../models/person.model';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { AbsenceCodeService } from "../../services/absencecode.service";
import { AlertService, DialogType } from '../../services/alert.service';
import { StampProposalDetail } from "./stampproposaldetail.component";
import { AbsenceCode } from '../../models/absencecode.model';

@Component({
  selector: 'stampproposal',
  templateUrl: './stampproposal.component.html',
  styleUrls: ['./stampproposal.component.css']
})
export class StampProposalComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private absencecodeService: AbsenceCodeService,
    private localStorage: LocalStoreManager,
    private stampcorrectionService: StampCorrectionService) {
    this.dateToday = new Date();
  }

  row = [];
  stampproposals: StampCorrection[] = [];
  proposedStamp: StampCorrection = new StampCorrection();
  editedStamp: StampCorrection;
  sourceStamp: StampCorrection;
  absencecodes: AbsenceCode[] = [];


  columns = [
    { name: 'bookingTime' },
    { name: 'Suunta', prop: 'dir' },
    { name: 'Syykoodi', prop: 'reasonTitle' },
    { name: '2. syykoodi', prop: 'reason2Title' },
    { name: 'Kommentti', prop: 'comment' },
    { name: 'Tekijä', prop: 'user' }
  ];

  @ViewChild('stampModal', { static: true })
  stampModal: ModalDirective;

  @ViewChild('stampEditor', { static: true })
  stampEditor: StampProposalDetail;
  editingStampName: { name: string };
  public formResetToggle = true;

  dateNow: IMyDate = this.getToday();
  dateToday: Date;
  persons: Person[] = [];
  params = new ReportParam();
  guser: boolean;
  allowdate: boolean;
  personname: string;
  employeeId: string;
  currentDate: Date = new Date();

  onDateChanged(event: IMyDateModel): void {
    let newDate: Date = new Date(event.jsdate.getTime());
    this.currentDate = newDate;
    this.allowdate = this.dateToday >= newDate;
  }

  getToday(): IMyDate {
    let date: Date = new Date();
    date.setDate(date.getDate());
    this.dateToday = date;
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  getNextDay(): IMyDate {
    this.currentDate.setDate(this.currentDate.getDate() + 1);
    this.allowdate = this.dateToday >= this.currentDate;
    return { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
  }

  getPreviousDay(): IMyDate {
    this.currentDate.setDate(this.currentDate.getDate() - 1);
    this.allowdate = this.dateToday >= this.currentDate;
    return { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
  }

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd.mm.yyyy',
    // disableSince: this.dateNow,
    width: "100%",
    showClearDateBtn: false,
    alignSelectorRight: false,
    showDecreaseDateBtn: true,
    showIncreaseDateBtn: true
  };

  public locale: string;
  public startDate: any = { date: { year: this.dateNow.year, month: this.dateNow.month, day: this.dateNow.day } };

  ngOnInit() {
    this.allowdate = true;
    this.locale = this.localStorage.getDataObject("language");
    this.employeeId = this.localStorage.getDataObject("employee_id");
    //this.params.lang = this.localStorage.getDataObject("language");
    this.personname = this.localStorage.getDataObject("personname");
    this.fetchstamps();
  }

  ngAfterViewInit() {

    this.stampEditor.changesSavedCallback = () => {
      this.fetchstamps();
      this.stampModal.hide();
    };

    this.stampEditor.changesCancelledCallback = () => {
      this.editedStamp = null;
      this.sourceStamp = null;
      this.stampModal.hide();
    };
  }

  private stampProposalParameters() {
    //this.params.lang = this.localStorage.getDataObject("language");
    this.params.startDate = this.startDate.formatted;
    if (!this.params.startDate) {
      var d = this.startDate.date;
      this.params.startDate = ("0" + d.day).slice(-2) + "." + ("0" + d.month).slice(-2) + "." + d.year;
    }
  }

  previousday() {
    this.startDate = { date: this.getPreviousDay() };
    this.fetchstamps();
  }

  nextday() {
    this.startDate = { date: this.getNextDay() };
    this.fetchstamps();
  }

  fetchstamps() {
    this.authService.refreshLogin()
      .subscribe(data => {
        this.authService.refresh_userdata(data);
        this.stampProposalParameters();
        var d = this.currentDate.toLocaleDateString("sv-SE");
        this.absencecodeService.getabsencecodes(1, d)
          .subscribe(data => {
            if (data != null) {
              this.absencecodes = data;
            }
            this.stampcorrectionService.getStampCorrections(this.params)
              .subscribe(data => {
                if (data != null) {
                  this.stampproposals = data as StampCorrection[];
                }
              },
                (error: any) => {
                });
          },
            (error: any) => {
            });
      },
        (error: any) => {
        });
  }


  getRowClass = (row) => {
    if (row.deleted)
      return {
        'row-deleted': true
      };
    if (row.stampType == 2)
      return {
        'row-inserted': true
      };
    else if (row.stampType == 3)
      return {
        'row-edited': true
      };
    else if (row.stampType == 4)
      return {
        'row-proposed': true
      };
  }

  newRow() {
    this.editingStampName = null;
    this.sourceStamp = null;
    this.editedStamp = this.stampEditor.newStamp(this.startDate, this.employeeId, this.absencecodes);
    this.stampModal.show();
  }

  editRow(corrStamp: StampCorrection) {
    this.editingStampName = { name: corrStamp.bookingTime };
    this.sourceStamp = corrStamp;
    this.editedStamp = this.stampEditor.editStamp(corrStamp, this.startDate, this.absencecodes);
    this.stampModal.show();
  }

  deleteRow(corrStamp) {
    let delQ = this.translationService.getTranslation('notifications.DeleteStamp');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteStamp(corrStamp));
    console.log('Delete row', corrStamp);
  }

  onEditorModalHidden() {
    this.editingStampName = null;
    this.stampEditor.resetForm(true);
  }

  deleteStamp(corrStamp) {
    this.stampcorrectionService.deleteStamp(corrStamp, "2")
      .subscribe(results => {
        this.stampproposals = this.stampproposals.filter(item => item !== corrStamp)
      },
        error => {
          if (error == "Unauthorized")
            this.authService.logout();
          else
            this.alertService.error(this.translationService.getTranslation('stampcorrection.DeleteStamp'));
        });
  }

}


