<div class="parent">
  <div class="stamp-container center-block">
    <div class="form-group">
      <div class="form-group1">
        <p>{{'helps.SelectAdvanceTime' | translate}}</p>
      </div>
      <div class="col-xs-12">
        <my-date-picker name="mydate" [options]="myDatePickerOptions" [locale]="locale" [(ngModel)]="model" required #dateField="ngModel"></my-date-picker>
      </div>
    </div>
    <br>
    <br>
    <div class="col-xs-12">
      <input type="text" class="form-control" placeholder={{commentPh}} [(ngModel)]="myComment" name="myComment">
    </div>
    <div class="footer">
      <div class="form-group">
        <p><button type="button" (click)="advanceBooking()" class="btn buttonAdvanced"> <strong>{{'controls.AdvanceBooking' | translate}}</strong></button></p>
        <p><button type="button" (click)="goback()" class="btn buttonCancel"> <strong>{{'common.Cancel' | translate}}</strong></button></p>
      </div>
    </div>
  </div>
</div>
