import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { AlertService, DialogType } from '../../services/alert.service';
import { RealStamp } from '../../models/realstamp.model';
import { StampData } from '../../models/stampdata.model';
import { AbsenceCode } from '../../models/absencecode.model';
import { CircAdds } from '../../models/circadds.model';
import { RealTimeStampService } from "../../services/realtimestamp.service";
import { DataService } from '../../services/data.service';
import { AbsenceCodeService } from "../../services/absencecode.service";
import { CircAddsService } from "../../services/circadds.service";
import { GeoLocationService } from '../../services/geo-location.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';

declare type Position = any; // or a more specific type definition

@Component({
  selector: 'realtimestamp',
  templateUrl: './realtimestamp.component.html',
  styleUrls: ['./realtimestamp.component.css']
})

export class RealTimeStampComponent implements OnInit {

  realStamp: RealStamp = new RealStamp();
  stampData: StampData;
  dateNow: Date = new Date();
  myDate: Date;
  onInit: boolean;
  atWork: boolean;
  noReturnTime: boolean;
  advanceBooking: boolean;
  absencecode: number;
  secondcode: number;
  rcodetype: number;
  absencecodes: AbsenceCode[] = [];
  circadds: CircAdds[] = [];
  private interval;
  addPosition: boolean;
  useLunchstamp: boolean;
  currentLocation: Position | null = null;
  coordstr: string;
  errorMsg: string;
  public locale: string;
  mobileDevice: boolean;
  useJobnumber: boolean;
  showComment: number;
  extraData: string;
  myComment: string;
  commentPh: string;

  constructor(
    public dataservice: DataService,
    private realtimestampService: RealTimeStampService,
    private translationService: AppTranslationService,
    private router: Router,
    private absencecodeService: AbsenceCodeService,
    private circaddsService: CircAddsService,
    private ref: ChangeDetectorRef,
    private geoLocationService: GeoLocationService,
    private authService: AuthService,
    private alertService: AlertService,
    private localStorage: LocalStoreManager) {
  }

  ngOnInit() {
    this.mobileDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
    //this.mobileDevice = (typeof window.orientation !== 'undefined');
    if (!this.authService.isLoggedIn)
      this.authService.redirectLogoutUser();
    else {
      if (this.dataservice.stampData) {
        this.stampData = this.dataservice.stampData;
        this.atWork = this.stampData.inside;
        this.addPosition = this.stampData.addPosition;
        this.locale = this.dataservice.stampData.language;
        this.absencecodes = this.dataservice.absencecodes;
      }
      else {
        this.getRealTimeData();
        //this.getCircAdds();
        // check if we have loaded absencecodes
        //if (this.dataservice.absencecodes == null || this.dataservice.absencecodes.length == 0)
          this.getAbsenceCodes();
        //else
        //  this.absencecodes = this.dataservice.absencecodes;
      }
      this.advanceBooking = false;
      this.useJobnumber = this.localStorage.getDataObject("usejobnumber");
      this.showComment = this.localStorage.getDataObject("showcomment");
      this.useLunchstamp = this.localStorage.getDataObject("uselunchstamp");
      this.commentPh = this.translationService.getTranslation('helps.Comment');
      this.myDate = new Date();
      this.interval = setInterval(() => {      // for updating time on screen   
        this.myDate = new Date();
      }, 5000);
    }

  }

  refresh() {
    this.authService.refreshLogin()
      .subscribe(data => {
        this.authService.refresh_userdata(data);
        this.getRealTimeData();
        this.getAbsenceCodes();
      },
        (error: any) => {
          //console.log(error); 
        });
  }

  change() {
    this.atWork = !this.atWork;
  }


  getAbsenceCodes() {
    this.absencecodeService.getabsencecodes(2, null)
      .subscribe(data => {
        this.absencecodes = data;
      },
        (error: any) => {
          //console.log(error);
        });
  }

  getCircAdds() {
    this.circaddsService.getcircadds()
      .subscribe(data => {
        this.circadds = data;
        if (this.circadds.length > 0 ) {
          this.selectedCircAdd = this.circadds[0];
          this.extraData = this.circadds[0].circ_add_id.toString();
        }
      },
        (error: any) => {
          //console.log(error);
        });
  }

  getRealTimeData() {
    // get realtime data
    this.realtimestampService.getRealTimeStampData()
      .subscribe(data => {
        if (data != null) {
          this.stampData = data;
          this.atWork = this.stampData.inside;
          this.addPosition = this.stampData.addPosition;
          this.locale = this.stampData.language;
        }
      },
        (error: any) => {
          //console.log(error);
        });
  }


  toNumber() {
    if (this.absencecodes.length > 1) {
      this.rcodetype = this.selectedAbsenceCode.reasoncode_type;
      this.absencecode = this.selectedAbsenceCode.reasoncode_id;
      this.noReturnTime = this.selectedAbsenceCode.no_ct600_returntime;
      this.advanceBooking = this.selectedAbsenceCode.advance_booking;
      if (this.rcodetype == 6) // olosuhdelisä
      {
        this.atWork = false;
        this.getCircAdds();
      }
      else if (this.rcodetype == 3) // kyselykoodi
      {
        this.realStamp.absencecode = this.absencecode;
        this.realtimestampService.getQuerycode(this.realStamp)
          .subscribe(data => {
            if (data != null) {
              this.alertService.showDialog(data);
              this.selectedAbsenceCode = null;
            }
          },
            (error: any) => {
              //console.log(error); 
            });

      }

    }
  }

  toSecondNumber() {
    this.secondcode = this.selectedSecondCode.reasoncode_id;
  }

  selectedAbsenceCode = this.absencecodes[0];
  selectedSecondCode = this.absencecodes[0];

  toCircAdd() {
    this.extraData = this.selectedCircAdd.circ_add_id;
  }
  selectedCircAdd = this.circadds[0];


  private makestamp() {
    this.realStamp.comment = this.myComment;
    this.realtimestampService.makeStamp(this.realStamp)
      .subscribe(data => {
        if (data != null) {
          this.stampData = data;
          this.atWork = this.stampData.inside;
          this.absencecode = 0;
          this.selectedAbsenceCode = null;
          this.selectedSecondCode = null;
          this.advanceBooking = false;
          this.myComment = null;
          if (this.atWork && this.stampData.taMessage != null) {
            this.alertService.showDialog(this.stampData.taMessage);
          }
        }
      },
        (error: any) => {
          //console.log(error); 
        });
  }

  private in() {
    this.authService.refreshLogin()
      .subscribe(data => {
        this.authService.refresh_userdata(data);
        this.realStamp.direction = 1;
        this.realStamp.returndatetime = null;
        this.realStamp.absencecode = this.absencecode;
        if (this.rcodetype == 6) {
          this.realStamp.extdata = this.extraData;
          this.extraData = null;
          this.realStamp.absencecode = 902; // olosuhdelisä
        }
        if (this.addPosition && this.mobileDevice) {
          // Returns an observable which returns the current position
          let self = this;
          //const accuracy = { enableHighAccuracy: true };
          self.geoLocationService.getLocation().subscribe(position => {
            self.currentLocation = position;
            self.ref.detectChanges();
            this.realStamp.coordstr = position.coords.latitude + ',' + position.coords.longitude;
            if (this.rcodetype == 5) {
              this.router.navigate(['/returntime']);
            }
            else {
              this.makestamp();
            }
          },
            error => {
              //console.error(error);
            });
        }
        else {
          this.realStamp.coordstr = null;
          if (this.rcodetype == 5) {
            this.router.navigate(['/returntime']);
          }
          else {
            this.makestamp();
          }
        }
      },
        (error: any) => {
          //console.log(error); 
        });
  }

  private out(lunch: number) {
    this.authService.refreshLogin()
      .subscribe(data => {
        this.authService.refresh_userdata(data);
        this.realStamp.direction = 0;
        this.realStamp.returndatetime = null;
        if (lunch == 1)
          this.realStamp.absencecode = 9;
        else {
          this.realStamp.absencecode = this.absencecode;
          this.realStamp.secondcode = this.secondcode;
        }
        if (this.addPosition && this.mobileDevice) {
          // Returns an observable which returns the current position
          let self = this;
          //const accuracy = { enableHighAccuracy: true };
          self.geoLocationService.getLocation().subscribe(position => {
            self.currentLocation = position;
            self.ref.detectChanges();
            this.realStamp.coordstr = position.coords.latitude + ',' + position.coords.longitude;
            if (this.absencecode > 0 && (!this.noReturnTime)) {
              this.router.navigate(['/returntime']);
            }
            else {
              this.makestamp();
            }
          },
            error => {
              //console.error(error);
            });
        }
        else {
          this.realStamp.coordstr = null;
          if (this.absencecode > 0 && (!this.noReturnTime)) {
            this.router.navigate(['/returntime']);
          }
          else {
            this.makestamp();
          }
        }
      },
        (error: any) => {
          //console.log(error); 
        });
  }

  private jobnumber() {
    this.authService.refreshLogin()
      .subscribe(data => {
        this.authService.refresh_userdata(data);
        this.realStamp.direction = 1;
        this.realStamp.returndatetime = null;
        this.realStamp.absencecode = 900; // start job number
        if (this.addPosition && this.mobileDevice) {
          // Returns an observable which returns the current position
          let self = this;
          //const accuracy = { enableHighAccuracy: true };
          self.geoLocationService.getLocation().subscribe(position => {
            self.currentLocation = position;
            self.ref.detectChanges();
            this.realStamp.coordstr = position.coords.latitude + ',' + position.coords.longitude;
            this.router.navigate(['/jobnumber']);
          },
            error => {
              //console.error(error);
            });
        }
        else {
          this.realStamp.coordstr = null;
          this.router.navigate(['/jobnumber']);
        }
      },
        (error: any) => {
          //console.log(error); 
        });
  }

  private advancebooking() {
    this.authService.refreshLogin()
      .subscribe(data => {
        this.authService.refresh_userdata(data);
        this.realStamp.direction = 1;
        this.realStamp.returndatetime = null;
        this.realStamp.absencecode = this.absencecode;
        this.realStamp.coordstr = null;
        this.router.navigate(['/advancebooking']);
      },
        (error: any) => {
          //console.log(error); 
        });
  }


  ngOnDestroy() {
    this.dataservice.stampData = this.stampData;
    this.dataservice.realStamp = this.realStamp;
    this.dataservice.absencecodes = this.absencecodes;
    if (this.interval)
      clearInterval(this.interval);
  }

}
