import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { AbsenceCode } from '../models/absencecode.model';
import { RealStampParam } from '../models/realstampparam.model';

@Injectable()
export class AbsenceCodeService {
  private absencecodesUrl: string = 'api/absencecodes';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getabsencecodes(num: number, corrDate: string): Observable<AbsenceCode[]> {
    var realstampParam = new RealStampParam();
    //realstampParam.employee_id = this.localStorage.getDataObject("employee_id");
    realstampParam.language = this.localStorage.getDataObject("language");
    //realstampParam.siteName = this.localStorage.getDataObject("sitename");
    realstampParam.abstype = num;
    realstampParam.corrDate = corrDate;
    return this.http.put<AbsenceCode[]>(this.absencecodesUrl, realstampParam)
  }

}

