import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BookingId } from '../models/bookingid.model';

@Injectable()
export class BookingIdService {
  private bookingidsUrl: string = 'api/bookingids';

  constructor(
    private http: HttpClient) { }

  getbookingids(): Observable<BookingId[]> {
    let endpointUrl = `${this.bookingidsUrl}/`;
    return this.http.get<BookingId[]>(endpointUrl);
  }

}

