import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RealStamp } from '../models/realstamp.model';
import { StampData } from '../models/stampdata.model';


@Injectable()
export class RealTimeStampService {
  private rtstampsUrl: string = 'api/realtimestamps';
  private getQueryUrl: string = 'api/getquerycode';
  public searchField = null;


  constructor(
    private http: HttpClient) { }

  getRealTimeStampData(): Observable<StampData> {
    //realstampParam.employee_id = this.localStorage.getDataObject("employee_id");
    //realstampParam.language = this.localStorage.getDataObject("language");
    //realstampParam.siteName = this.localStorage.getDataObject("sitename");
    return this.http.get<StampData>(this.rtstampsUrl);
  }

  makeStamp(stamp: RealStamp): Observable<StampData> {
    if (stamp.direction == 2) {
      stamp.stamptime = stamp.returndatetime;
      stamp.returndatetime = null;
    }
    else {
      var d = new Date();
      let fullyear = d.getFullYear();
      let month = ('00' + (d.getMonth() + 1)).slice(-2);
      let day = ('00' + d.getDate()).slice(-2);
      let hour = ('00' + d.getHours()).slice(-2);
      let minute = ('00' + d.getMinutes()).slice(-2);
      stamp.stamptime = day + "/" + month + "/" + fullyear + " " + hour + ":" + minute + ":00";
    }
    //stamp.employee_id = this.localStorage.getDataObject("employee_id");
    //stamp.language = this.localStorage.getDataObject("language");
    //stamp.siteName = this.localStorage.getDataObject("sitename");
    return this.http.post<StampData>(this.rtstampsUrl, stamp);
  }

  getQuerycode(stamp: RealStamp): Observable<string> {
    //stamp.employee_id = this.localStorage.getDataObject("employee_id");
    //stamp.language = this.localStorage.getDataObject("language");
    //stamp.siteName = this.localStorage.getDataObject("sitename");
    return this.http.post(this.getQueryUrl, stamp, { responseType: 'text' })
      .pipe(
      )
  }

}

