import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStoreManager } from './local-store-manager.service';
import { ReportParam } from '../models/reportparam.model';
import { StampReport } from '../models/stampreport.model';
import { SummaryReport } from '../models/summaryreport.model';
import { PrintReportParam } from '../models/printreportparam.model';
import { Observable } from 'rxjs';

@Injectable()
export class StampReportService {
  private reportUrl: string = 'api/stampreport';
  private summaryUrl: string = 'api/summaryreport';
  private printUrl: string = 'api/stampprint';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getStampReport(params: ReportParam): Observable<StampReport[]> {
    //params.system = this.localStorage.getDataObject("sitename");
    //params.user = this.localStorage.getDataObject("username");
    return this.http.post<StampReport[]>(this.reportUrl, params);
  }

  getSummaryReport(params: ReportParam): Observable<SummaryReport[]> {
    //params.system = this.localStorage.getDataObject("sitename");
    //params.user = this.localStorage.getDataObject("username");
    return this.http.post<SummaryReport[]>(this.summaryUrl, params);
  }

  printReport(params: PrintReportParam): Observable<any> {
/*    params.system = this.localStorage.getDataObject("sitename");*/
    return this.http.post(this.printUrl, params, { responseType: "blob" })
  }

  

}
