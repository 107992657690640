import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { UserLogin } from '../models/user-login.model';
import { HttpClient } from '@angular/common/http';
import { JwtHelper } from './jwt-helper';

@Injectable()
export class AuthService {

  public systemName: any;
  public systemNameChange: Subject<string> = new Subject<string>();
  public loginRedirectUrl: string;
  private previousIsLoggedInCheck = false;
  private _loginStatus = new Subject<boolean>();
  private _loginUrl: string = '/users/authenticate';
  private _baseUrl: string = this.baseUrl().replace(/\/$/, '');
  public get loginUrl() { return this._baseUrl + this._loginUrl; }
  public get homeUrl() { return "/"; }
  public reLoginDelegate: () => void;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorage: LocalStoreManager) {
    this.initializeLoginStatus();
    this.systemName = "ESMIKKO";
  }

  baseUrl() {
    if (window.location.origin)
      return window.location.origin
    return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }

  private initializeLoginStatus() {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
    });
  }

  login(logindata: UserLogin) {
    if (this.isLoggedIn)
      this.logout();
    logindata.rights = 0; // real login
    var d = new Date();
    logindata.logintime = d.toLocaleString('en-GB').replace(/\u200E/g, "");
    return this.http.post(this.loginUrl, logindata)
      .pipe(
      )
  }

  public store_userdata(user) {
    let jwtHelper = new JwtHelper();
    let decodedIdToken = jwtHelper.decodeToken(user.access_token);
    let expires = decodedIdToken.exp;
    let username = decodedIdToken.username;
    let sitename = decodedIdToken.sitename;
    let employeeId = decodedIdToken.employeeId;
    let language = decodedIdToken.language;
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    this.localStorage.saveSyncedSessionData(user, "current_user");
    this.localStorage.saveSyncedSessionData(expires, "expires_in");
    this.localStorage.saveSyncedSessionData(sitename, "sitename");
    this.localStorage.saveSyncedSessionData(language, "language");
    this.localStorage.saveSyncedSessionData(employeeId, "employee_id");
    this.localStorage.saveSyncedSessionData(user.siteid, "siteid");
    if (user.rememberMe) {
      this.localStorage.savePermanentData(username, "username");
      this.localStorage.savePermanentData(user.password, "password");
      this.localStorage.savePermanentData(user.rememberMe, "rememberme");
    }
    else {
      this.localStorage.saveSyncedSessionData(username, "username");
      this.localStorage.saveSyncedSessionData(user.password, "password");
      this.localStorage.saveSyncedSessionData(user.rememberMe, "rememberme");
    }
    this.localStorage.saveSyncedSessionData(user.access_token, "id_token");
    this.localStorage.saveSyncedSessionData(user.refresh_token, "refresh_token");
    this.localStorage.saveSyncedSessionData(user.rights, "rights");
    this.localStorage.saveSyncedSessionData(user.guser, "guser");
    this.localStorage.saveSyncedSessionData(user.useJobnumber, "usejobnumber");
    this.localStorage.saveSyncedSessionData(user.showComment, "showcomment");
    this.localStorage.saveSyncedSessionData(user.useLunchstamp, "uselunchstamp");
    this.localStorage.saveSyncedSessionData(user.enableCorrstamp, "enablecorrstamp");
    this.localStorage.saveSyncedSessionData(user.sendMail, "sendmail");
    this.localStorage.saveSyncedSessionData(user.personName, "personname");
    this.reevaluateLoginStatus(user);
  }

  refresh_userdata(user) {
    let jwtHelper = new JwtHelper();
    let decodedIdToken = jwtHelper.decodeToken(user.access_token);
    let expires = decodedIdToken.exp;
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    this.localStorage.saveSyncedSessionData(expires, "expires_in");
    this.localStorage.saveSyncedSessionData(user.access_token, "id_token");
    this.localStorage.saveSyncedSessionData(user.refresh_token, "refresh_token");
    this.reevaluateLoginStatus(user);
  }

  delete_userdata() {
    this.localStorage.deleteData("current_user");
    let remember = this.localStorage.getDataObject("rememberme");
    if (!remember) {
      this.localStorage.deleteData("username");
      this.localStorage.deleteData("password");
      this.localStorage.deleteData("remember");
    }
    this.localStorage.deleteData("id_token");
    this.localStorage.deleteData("rights");
    this.localStorage.deleteData("expires_in");
    this.localStorage.deleteData("refresh_token");
    this.localStorage.deleteData("language");
    this.localStorage.deleteData("guser");
    this.localStorage.deleteData("usejobnumber");
    this.localStorage.deleteData("uselunchstamp");
    this.localStorage.deleteData("enablecorrstamp");
    this.localStorage.deleteData("sendmail");
    this.localStorage.deleteData("personname");
    this.localStorage.deleteData("employee_id");
  }


  logout() {
    this.delete_userdata();
    this.reevaluateLoginStatus();
    this.redirectLogoutUser();
  }

  get accessTokenExpiryDate(): Date {

    this.reevaluateLoginStatus();
    return this.localStorage.getDataObject<Date>("expires_in", true);
  }

  get isSessionExpired(): boolean {

    if (this.accessTokenExpiryDate == null) {
      return true;
    }

    return !(this.accessTokenExpiryDate.valueOf() * 1000 > new Date().valueOf());
  }

  private reevaluateLoginStatus(currentUser?: UserLogin) {

    let user = currentUser || this.localStorage.getDataObject<UserLogin>("current_user");
    let isLoggedIn = user != null;
    if (this.previousIsLoggedInCheck != isLoggedIn) {
      setTimeout(() => {
        this._loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;
  }

  getLoginStatusEvent(): Observable<boolean> {
    return this._loginStatus.asObservable();
  }

  get currentUser(): UserLogin {
    let user = this.localStorage.getDataObject<UserLogin>("current_user");
    this.reevaluateLoginStatus(user);
    return user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser != null;
  }

  redirectLoginUser() {
    let redirect = this.loginRedirectUrl ? this.loginRedirectUrl : this.homeUrl;
    this.loginRedirectUrl = null;
    this.router.navigate([redirect]);
  }

  redirectLogoutUser() {
    let num = this.localStorage.getDataObject("siteid");
    this.router.navigate(['/login'], { queryParams: { id: num } });
  }

  refreshLogin() { 
    let user = this.localStorage.getDataObject("username");
    let employeeId = this.localStorage.getDataObject("employee_id");
    let site = this.localStorage.getDataObject("sitename");
    let lang = this.localStorage.getDataObject("language");
    let endpointUrl = `${this.loginUrl}/${user}:${employeeId}:${site}:${lang}`;
    return this.http.get(endpointUrl)
      .pipe()
  }
}


