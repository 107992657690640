<div>
  <form class="form-horizontal" (ngSubmit)="save()" name="correctionForm" #f="ngForm">
    <div class="form-group">
      <label class="control-label col-sm-2" for="bookingTime">{{'stampcorrection.BookingTime' | translate}}</label>
      <div class="col-sm-10">
        <ngb-timepicker [(ngModel)]="clockTime" name="clockTime"> </ngb-timepicker>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="bookingid">{{'stampcorrection.Direction' | translate}}</label>
      <div class="col-sm-10">
        <select [(ngModel)]="selectedBookingId" name="bookingid" (change)="toBookingId()" >
          <option *ngFor="let bid of bookingids" [selected]="bid.booking_id == stampEdit.bookingId" [ngValue]=bid>
            {{bid.descr}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="reasoncode">{{'stampcorrection.Reasoncode' | translate}}</label>
      <div class="col-sm-10">
        <select [(ngModel)]="selectedAbsenceCode" name="absencecode" (change)="toNumber()">
          <option value="0">{{'helps.NoAbsenceCode' | translate}}</option>
          <option *ngFor="let absencecode of absencecodes" [selected]="absencecode.reasoncode_id == stampEdit.reason"
                  [ngValue]=absencecode>
            {{absencecode.infotext}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="comment">{{'stampcorrection.Comment' | translate}}</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" [(ngModel)]="stampEdit.comment" name="comment">
      </div>
    </div>
    <div class="col-sm-7">
      <div class="pull-right">
        <button type="button" (click)="cancel()" class="btn btn-danger"><i class='fa fa-times'></i> {{'common.Cancel' | translate}}</button>
        <button type="submit" [disabled]="!f.form.valid" class="btn btn-primary"><i class='fa fa-save'></i>{{'common.Save' | translate}}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </form>
</div>
