<div id="app_container">
  <div class="app-container center-block">
    <nav id="header" class="app-component navbar navbar-inner navbar-fixed-top">
      <nav class="container">
        <div class="navbar-header">
          <button type="button" class="app-component navbar-toggle collapsed mainnavbar" data-toggle="collapse" data-target=".menuItemsContainer.app-component" aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="app-component navbar-brand">
            <span role="button" name="prgicon" (click)="about()" style="margin-top:10px; margin-left:10px;font-size: 30px;" class="glyphicon glyphicon-time pull-left"></span>
            <span class="app-component siteTitle" style="color:#ffffff; font:medium; margin-left:40px">{{siteTitle}}</span>
          </a>
        </div>
        <div *ngIf="isUserLoggedIn" class="app-component collapse navbar-collapse menuItemsContainer">
          <ul class="app-component nav navbar-nav nav-pills navBarPadding">
            <li routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.in" *ngIf="canViewStamp">
              <a [routerLink]="['/realtimestamp']">{{'menu.RealTimeStamp' | translate}}</a>
            </li>
            <li routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.in">
              <a [routerLink]="['/stampreport']">{{'menu.StampReport' | translate}}</a>
            </li>
            <li routerLinkActive="active" *ngIf="canViewStampCorrection" data-toggle="collapse" data-target=".navbar-collapse.in">
              <a [routerLink]="['/stampcorrection']">{{'menu.StampCorrection' | translate}}</a>
            </li>
            <li routerLinkActive="active" *ngIf="canViewStampProposal" data-toggle="collapse" data-target=".navbar-collapse.in">
              <a [routerLink]="['/stampproposal']">{{'menu.StampProposal' | translate}}</a>
            </li>
          </ul>
          <ul class="app-component nav navbar-nav nav-pills navBarPadding navbar-right">
            <li (click)="logout()"><a href="javascript:;"><span class="glyphicon glyphicon-log-out"></span></a></li>
          </ul>
        </div>
      </nav>
    </nav>
    <div id="mainContent" class="app-component">
      <router-outlet></router-outlet>
      <alert></alert>
    </div>
  </div>
  <div *ngIf="shouldShowLoginModal" class="modal fade" bsModal #loginModal="bs-modal" (onHidden)="onLoginModalHidden()"
       [config]="{backdrop: 'static'}" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <app-login #loginControl isModal="true"></app-login>
    </div>
  </div>

</div>

