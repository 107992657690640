import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { AboutComponent } from "./components/about/about.component";
import { RealTimeStampComponent } from './components/realtimestamp/realtimestamp.component';
import { ReturnTimeComponent } from './components/returntime/returntime.component';
import { AdvanceBookingComponent } from './components/advancebooking/advancebooking.component';
import { JobNumberComponent } from './components/jobnumber/jobnumber.component';
import { StampReportComponent } from './components/stampreport/stampreport.component';
import { StampCorrectionComponent } from './components/stampcorrection/stampcorrection.component';
import { StampProposalComponent } from './components/stampproposal/stampproposal.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';


const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [AuthGuard], data: { title: "Home" } },
  { path: "login", component: LoginComponent, data: { title: "Login" } },
  { path: "about", component: AboutComponent, data: { title: "About" } },
  { path: 'realtimestamp', component: RealTimeStampComponent },
  { path: 'returntime', component: ReturnTimeComponent },
  { path: 'advancebooking', component: AdvanceBookingComponent },
  { path: 'jobnumber', component: JobNumberComponent },
  { path: 'stampreport', component: StampReportComponent },
  { path: 'stampcorrection', component: StampCorrectionComponent },
  { path: 'stampproposal', component: StampProposalComponent },
  { path: "home", redirectTo: "/", pathMatch: "full" },
  { path: '**', redirectTo: '' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard]
})
export class AppRoutingModule { }
