<div class="parent">
  <div class="stamp-container center-block">
    <div class="header">
      <div class="form-group">
        <p>
          <button appLoadTooltip type="button" (click)="change()" style="margin-right:5px;font-size:20px" class="glyphicon glyphicon-sort pull-left" data-toggle="tooltip"
                  data-placement="top" title="{{'helps.Change' | translate}}"></button>
          {{myDate | localizedDate:'EEEE':locale | capitalize}}, {{myDate | date:'dd.MM.yyyy'}} {{myDate | date:'shortTime'}}
          <button appLoadTooltip type="button" (click)="refresh()" style="margin-right:5px;font-size:20px" class="glyphicon glyphicon-refresh pull-right" data-toggle="tooltip"
                  data-placement="top" title="{{'helps.Refresh' | translate}}"></button>
        </p>
      </div>
    </div>
    <div class="body">
      <div class="form-group" *ngIf="stampData">
        <div class="form-group2">
          <p>{{stampData.name}}</p>
        </div>
        <p>{{'helps.LastStamp' | translate}} {{stampData.stampTime}}</p>
        <p>{{stampData.status}} {{stampData.saldo}}</p>
      </div>
      <div class="form-group" *ngIf="absencecodes">
        <div class="form-group1">
          {{'helps.AbsenceCode' | translate}}
          <select [(ngModel)]="selectedAbsenceCode" (change)="toNumber()">
            <option value="0">{{'helps.NoAbsenceCode' | translate}}</option>
            <option *ngFor="let absencecode of absencecodes"
                    [ngValue]=absencecode>
              {{absencecode.infotext}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group" *ngIf="absencecodes && absencecode > 0 && atWork && rcodetype != 6">
        <div class="form-group1">
          {{'helps.SecondCode' | translate}}
          <select [(ngModel)]="selectedSecondCode" (ngModelChange)="toSecondNumber()">
            <option data-subtext="(Default)" value="0">{{'helps.NoAbsenceCode' | translate}}</option>
            <option *ngFor="let absencecode of absencecodes"
                    [ngValue]=absencecode>
              {{absencecode.infotext}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group" *ngIf="absencecodes && absencecode > 0 && circadds && rcodetype == 6 && !atWork">
        <div class="form-group1">
          {{'common.ExtraData' | translate}}
          <select [(ngModel)]="selectedCircAdd" (change)="toCircAdd()">
            <option *ngFor="let circadd of circadds" [ngValue]=circadd [selected]="circadd.reasoncode_id == selectedCircAdd.reasoncode_id">
              {{circadd.circ_add_id}} {{circadd.description}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xs-12" *ngIf="((showComment%4)==1 && atWork) || ((showComment%4)==2 && !atWork) || ((showComment%4)==3)">
        <input type="text" class="form-control" placeholder={{commentPh}} [(ngModel)]="myComment" name="myComment">
      </div>
      <div class="footer">
        <div class="form-group" *ngIf="stampData">
          <div *ngIf="useJobnumber">
            <button type="button" (click)="jobnumber()" class="btn buttonjn"> {{'controls.JobNumber' | translate}}</button>
          </div>
          <button type="button" (click)="advancebooking()" [disabled]="!advanceBooking" class="btn buttonAdvanced"> {{'controls.AdvanceBooking' | translate}}</button>
          <div *ngIf="useLunchstamp && atWork">
            <button type="button" (click)="out(1)" class="btn buttonlunch"> {{'controls.LunchOut' | translate}}</button>
          </div>
          <div *ngIf="!atWork">
            <button type="button" (click)="in()" class="btn buttonin"> {{'controls.In' | translate}}</button>
          </div>
          <div *ngIf="atWork">
            <button type="button" (click)="out(0)" class="btn buttonout">{{'controls.Out' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
</div>

