import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LocalStoreManager } from '../services/local-store-manager.service';

@Pipe({
	name: 'localizedDate',
	pure: false
})
export class LocalizedDatePipe implements PipeTransform {


  constructor(
    private localStorage: LocalStoreManager) {
	}

  transform(value: any, pattern: string = 'mediumDate'): any {
    let lang = this.localStorage.getDataObject("language");
    if (!lang)
      lang = 'fi';
    const datePipe: DatePipe = new DatePipe(lang.toString());
    var daystr = datePipe.transform(value, pattern);
    if (lang == 'fi')
      daystr = daystr.substring(0, daystr.length - 2);
    return daystr;
	}

}
