import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { IMyDpOptions, IMyDate } from 'mydatepicker';
import { StampReport } from '../../models/stampreport.model';
import { SummaryReport } from '../../models/summaryreport.model';
import { StampReportService } from "../../services/stampreport.service";
import { ReportParam } from '../../models/reportparam.model';
import { PrintReportParam } from '../../models/printreportparam.model';
import { Person } from '../../models/person.model';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'stampreport',
  templateUrl: './stampreport.component.html',
  styleUrls: ['./stampreport.component.css']
})
export class StampReportComponent implements OnInit {

  row = [];
  extrow = [];
  selected = [];
  selectedDate: Date;
  dateNow: IMyDate = this.getToday();
  dateOlder: IMyDate = this.getOlderDate();
  stampreports: StampReport[] = [];
  summaryreport: SummaryReport[] = [];
  persons: Person[] = [];
  params = new ReportParam();
  printparams = new PrintReportParam();
  guser: boolean;
  personname: string;
  employeeId: string;
  selectedPerson: Person;
  selectNameStr: string;
  mobileDevice: boolean;

  getToday(): IMyDate {
    let date: Date = new Date();
    date.setDate(date.getDate());
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  getOlderDate(): IMyDate {
    let date: Date = new Date();
    date.setDate(date.getDate() - 3);
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd.mm.yyyy',
    disableSince: this.dateNow,
    width: "100%",
    showClearDateBtn: false,
    alignSelectorRight: false,
    showDecreaseDateBtn: true,
    showIncreaseDateBtn: true
  };

  public locale: string;
  public startDate: any = { date: { year: this.dateOlder.year, month: this.dateOlder.month, day: this.dateOlder.day } };
  public endDate: any = { date: { year: this.dateNow.year, month: this.dateNow.month, day: this.dateNow.day } };

  constructor(
    public dataservice: DataService,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private stampReportService: StampReportService,
    private localStorage: LocalStoreManager) {
  }

  ngOnInit() {
    this.mobileDevice = (typeof window.orientation !== 'undefined');
    this.locale = this.localStorage.getDataObject("language");
    this.employeeId = this.localStorage.getDataObject("employee_id");
    //this.params.lang = this.localStorage.getDataObject("language");
    this.personname = this.localStorage.getDataObject("personname");
    this.refresh();
   }

  private stampReportParameters() {
    //this.params.lang = this.localStorage.getDataObject("language");
    this.params.startDate = this.startDate.formatted;
    if (!this.params.startDate) {
      var d = this.startDate.date;
      this.params.startDate = ("0" + d.day).slice(-2) + "." + ("0" + d.month).slice(-2) + "." + d.year;
    }
    this.params.endDate = this.endDate.formatted;
    this.params.endDate = this.endDate.day + this.endDate.month + this.endDate.year;
    if (!this.params.endDate) {
      var d = this.endDate.date;
      this.params.endDate = ("0" + d.day).slice(-2) + "." + ("0" + d.month).slice(-2) + "." + d.year;
    }
  }

  initReportData() {
    this.employeeId = this.localStorage.getDataObject("employee_id");
    //this.params.lang = this.localStorage.getDataObject("language");
    this.stampReportParameters();
    this.stampReportService.getStampReport(this.params)
      .subscribe(data => {
        if (data != null) {
          this.stampreports = data as StampReport[];
        }
      },
        (error: any) => {
        });
  }

  refresh() {
    this.authService.refreshLogin()
      .subscribe(data => {
        this.authService.refresh_userdata(data);
        this.stampReportParameters();
        this.stampReportService.getStampReport(this.params)
          .subscribe(data => {
            if (data != null) {
              this.stampreports = data as StampReport[];
            }
            this.summaryreport = [];
          },
            (error: any) => {
            });
      },
      (error: any) => {
      });
  }

  summary() {
    this.authService.refreshLogin()
      .subscribe(data => {
        this.authService.refresh_userdata(data);
        this.stampReportParameters();
        this.stampReportService.getSummaryReport(this.params)
          .subscribe(data => {
            if (data != null) {
              this.summaryreport = data as SummaryReport[];
            }
          },
            (error: any) => {
            });
      },
        (error: any) => {
        });
  }

  printpdf() {
    //this.printparams.employee_id = this.params.employee_id;
    this.stampReportParameters();
    this.printparams.startDate = this.params.startDate;
    this.printparams.endDate = this.params.endDate;
    //this.printparams.lang = this.params.lang;
    this.stampReportService.printReport(this.printparams)
      .subscribe(data => {
        var url = window.URL.createObjectURL(data);
        window.open(url);
      },
        (error: any) => {
        });
  }


  getRowClass = (row) => {
    if ((row.flags & 4096) == 4096)
      return {
        'row-rc': true
      };
    if ((row.flags & 2048) == 2048)
      return {
        'row-empty': true
      };
    if ((row.flags&1024)==1024)
      return {
        'row-saldo': true
      };
    if ((row.flags & 64) == 64)
      return {
        'row-proposed': true
      };
    else if ((row.flags&1) == 1)
      return {
        'row-inserted': true
      };
    else if ((row.flags&2) == 2)
      return {
        'row-edited': true
      };

  }

}
