export class StampCorrection {
  emplBookingId: number;
/*  employee_id: string;*/
  bookingDate: string;
  bookingTime: string;
  original: string;
  stampType: number;
  deleted: boolean;
  bookingId: number;
  dir: string;
  reason: number;
  reasonTitle: string;
  secondReason: number;
  reason2Title: string;
  reasonType: number;
  flags: string;
  comment: string;
  //user: string;
  //language: string;
  //siteName: string;
  operationType: string;
  startDate: string;
  endDate: string;
  sendmail: boolean;
  dailyProg: number;
  extension_data: string;
}
