<div class="vertical-center-flex">
  <div class="login-container center-block">
    <div class="panel panel-primary boxshadow">
      <div class="panel-heading">
        <span class="glyphicon glyphicon-lock"></span> Login
        <button *ngIf="isModal" type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="panel-body">
        <div class="col-md-8 col-md-offset-2">
          <form *ngIf="formResetToggle" class="form-horizontal login" name="loginForm" #f="ngForm" novalidate
                (ngSubmit)="f.form.valid ? login() :
                          (!username.valid && showErrorAlert(1));
                          (!password.valid && showErrorAlert(2))">
            <div *ngIf="manySites==true" class="form-group">
              <label class="control-label col-md-3" for="login-sitename">{{'login.Sitename' | translate}}</label>
              <select class="form-control col-md-9" name="site" (change)="valueChange()" [(ngModel)]="site" required>
                <option *ngFor="let mysite of sites" [ngValue]="mysite">
                  {{mysite.siteName}}
                </option>
              </select>
            </div>
            <div class="form-group has-feedback">
              <label class="control-label col-md-4" for="login-username">{{'login.Username' | translate}}</label>
              <div class="col-md-8" [ngClass]="{'has-success': f.submitted && username.valid, 'has-error' : f.submitted && !username.valid}">
                <input type="text" id="username" name="username" autocomplete="new-username" placeholder="{{'login.UsernamePh' | translate}}" class="form-control" [(ngModel)]="userLogin.username" #username="ngModel" required />
                <span *ngIf="f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': username.valid, 'glyphicon-remove' : !username.valid}" aria-hidden="true"></span>
                <span class="errorMessage" *ngIf="f.submitted && !username.valid">
                  {{'login.UsernameRequired' | translate}}
                </span>
              </div>
            </div>
            <div class="form-group has-feedback">
              <label class="control-label col-md-4" for="login-password">{{'login.Password' | translate}}</label>
              <div class="col-md-8" [ngClass]="{'has-success': f.submitted && password.valid, 'has-error' : f.submitted && !password.valid}">
                <input type="password" autocomplete="new-password" id="login-password" name="password" placeholder="{{'login.PasswordPh' | translate}}" class="form-control" [(ngModel)]="userLogin.password" #password="ngModel" required />
                <span *ngIf="f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': password.valid, 'glyphicon-remove' : !password.valid}" aria-hidden="true"></span>
                <span class="errorMessage" *ngIf="f.submitted && !password.valid">
                  {{'login.PasswordRequired' | translate}}
                </span>
              </div>
            </div>
            <div class="form-group">
                <label class="control-label col-md-4" for="login-password">{{'login.RememberMe' | translate}}</label>
                <input type="checkbox" name="rememberMe" [(ngModel)]="userLogin.rememberMe">
            </div>
            <div class="form-group">
              <div class="col-md-offset-2 col-md-10">
                <button type="submit" class="btn btn-primary" [disabled]="!isSystem">{{'Login'}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
