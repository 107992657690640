import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTranslationService } from "../../services/app-translation.service";
import { RealStamp } from '../../models/realstamp.model';
import { StampData } from '../../models/stampdata.model';
import { RealTimeStampService } from "../../services/realtimestamp.service";
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { Jobtask } from '../../models/jobtask.model';
import { JobtaskService } from '../../services/jobtask.service';
import { AlertService, DialogType } from '../../services/alert.service';

@Component({
  selector: 'jobnumber',
  templateUrl: './jobnumber.component.html',
  styleUrls: ['./jobnumber.component.css']
})

export class JobNumberComponent implements OnInit {

  jobtasks: Jobtask[] = [];
  jobTsk: Jobtask = new Jobtask();
  realStamp: RealStamp = new RealStamp();
  stampData: StampData;
  absencecode: number;
  myComment: string;
  commentPh: string;
  tasknumber: string;
  gotJobnumbers: boolean;


  constructor(
    public dataservice: DataService,
    private translationService: AppTranslationService,
    private router: Router,
    private realtimestampService: RealTimeStampService,
    private jobtaskService: JobtaskService,
    private alertService: AlertService,
    private authService: AuthService  ) {
        this.getJobtasks();
  }

  ngOnInit() {
    if (this.dataservice.stampData == null || this.dataservice.realStamp == null)
      this.authService.logout();
    this.gotJobnumbers = false;
    this.stampData = this.dataservice.stampData;
    this.realStamp = this.dataservice.realStamp;
    this.commentPh = this.translationService.getTranslation('helps.Comment');
    //this.getActiveJobtask();

  }

  selectedJobtask = this.jobtasks[0];

  getJobtasks() {
    this.jobtaskService.getjobtasks(0)
      .subscribe(data => {
        this.jobtasks = data;
        this.gotJobnumbers = true;
        this.getActiveJobtask();
      },
        (error: any) => {
          console.log(error); 
        });
  }

  getActiveJobtask() {
    //this.jtp.rcode = 14;
    this.jobtaskService.getActiveJobtask()
      .subscribe(data => {
        if (data != null) {
          this.jobTsk.tasknumber = data.tasknumber;
          this.jobTsk.description = data.description;
          if (this.jobTsk.tasknumber == "NOCODE")
            this.alertService.error(this.translationService.getTranslation('notifications.NoInquiryCode'));
        }
      },
        (error: any) => {
          console.log(error);
        });
  }
  
  toNumber() {
    this.tasknumber = this.selectedJobtask.tasknumber;
  }

  public jobNumber() {
    this.realStamp.direction = 1;
    this.realStamp.returndatetime = null;
    this.realStamp.comment = this.myComment;
    this.realStamp.extdata = 'M' + this.tasknumber;
    this.realtimestampService.makeStamp(this.realStamp)
      .subscribe(data => {
        if (data != null) {
          this.stampData = data;
        }
        this.router.navigate(['/realtimestamp']);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public endjobNumber() {
    this.realStamp.direction = 1;
    this.realStamp.returndatetime = null;
    this.realStamp.comment = this.myComment;
    this.realStamp.absencecode = 901; // end job number
    this.realStamp.extdata = 'M' + this.tasknumber;
    this.realtimestampService.makeStamp(this.realStamp)
      .subscribe(data => {
        if (data != null) {
          this.stampData = data;
        }
        this.router.navigate(['/realtimestamp']);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public goback() {
    this.router.navigate(['/realtimestamp']);
  }

  ngOnDestroy() {
    this.dataservice.stampData = this.stampData;
    this.dataservice.realStamp = this.realStamp;
  }

}
