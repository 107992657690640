import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { DailyProgram } from '../models/dailyprogram.model';
import { ReportParam } from '../models/reportparam.model';

@Injectable()
export class DailyProgramService {
  private dailyprogramUrl: string = 'api/dailyprograms';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getdailyprograms(params: ReportParam): Observable<DailyProgram[]> {
    //params.system = this.localStorage.getDataObject("sitename");
    return this.http.put<DailyProgram[]>(this.dailyprogramUrl, params);
  }

}

