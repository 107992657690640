import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTranslationService } from "../../services/app-translation.service";
import { RealStamp } from '../../models/realstamp.model';
import { StampData } from '../../models/stampdata.model';
import { RealTimeStampService } from "../../services/realtimestamp.service";
import { DataService } from '../../services/data.service';
import { IMyDpOptions, IMyDate } from 'mydatepicker';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'returntime',
  templateUrl: './returntime.component.html',
  styleUrls: ['./returntime.component.css']
})

export class ReturnTimeComponent implements OnInit {

  realStamp: RealStamp = new RealStamp();
  selectedDate: Date;
  dateYesterday: IMyDate = this.getToday(-1);
  dateTomorrow: IMyDate = this.getToday(1);
  stampData: StampData;
  absencecode: number;
  secondcode: number;
  myTime: string;
  myComment: string;
  commentPh: string;
  clockTime = { hour: 8, minute: 0 };

  getToday(inc: number): IMyDate {
    let date: Date = new Date();
    date.setDate(date.getDate() + inc);
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd.mm.yyyy',
    disableUntil: this.dateYesterday,
    width: "100%",
    showClearDateBtn: false,
    alignSelectorRight: false,
    showIncreaseDateBtn: true
  };

  public locale: string;
  public model: any = { date: { year: this.dateTomorrow.year, month: this.dateTomorrow.month, day: this.dateTomorrow.day } };

  constructor(
    public dataservice: DataService,
    private translationService: AppTranslationService,
    private router: Router,
    public datepipe: DatePipe,
    private realtimestampService: RealTimeStampService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    if (this.dataservice.stampData == null || this.dataservice.realStamp == null)
      this.authService.logout();
    this.locale = this.dataservice.stampData.language;
    this.stampData = this.dataservice.stampData;
    this.realStamp = this.dataservice.realStamp;
    this.commentPh = this.translationService.getTranslation('helps.Comment');
  }

  public noreturnTime() {
    this.realStamp.direction = 0;
    this.realStamp.returndatetime = null;
    this.realStamp.comment = this.myComment;
    this.realtimestampService.makeStamp(this.realStamp)
      .subscribe(data => {
        if (data != null) {
          this.stampData = data;
        }
        this.router.navigate(['/realtimestamp']);
      },
        (error: any) => {
          //console.log(error); 
        });
  }

  public returnTime() {
    this.realStamp.direction = 0;
    this.realStamp.returndatetime = this.model.formatted;
    if (!this.realStamp.returndatetime) {
      var d = this.model.date;
      this.realStamp.returndatetime = ("0" + d.day).slice(-2) + "." + ("0" + d.month).slice(-2) + "." +
        d.year;
    }
    this.realStamp.returndatetime += ' ' + ("0" + this.clockTime.hour).slice(-2) + ":" + ("0" + this.clockTime.minute).slice(-2);
    this.realStamp.comment = this.myComment;
    this.realtimestampService.makeStamp(this.realStamp)
      .subscribe(data => {
        if (data != null) {
          this.stampData = data;
        }
        this.router.navigate(['/realtimestamp']);
      },
        (error: any) => {
          //console.log(error); 
        });
  }

  public goback() {
    this.router.navigate(['/realtimestamp']);
  }

  ngOnDestroy() {
    this.dataservice.stampData = this.stampData;
    this.dataservice.realStamp = this.realStamp;
  }

}
