import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTranslationService } from "../../services/app-translation.service";
import { RealStamp } from '../../models/realstamp.model';
import { StampData } from '../../models/stampdata.model';
import { RealTimeStampService } from "../../services/realtimestamp.service";
import { DataService } from '../../services/data.service';
import { IMyDpOptions, IMyDate } from 'mydatepicker';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'advancebooking',
  templateUrl: './advancebooking.component.html',
  styleUrls: ['./advancebooking.component.css']
})

export class AdvanceBookingComponent implements OnInit {

	realStamp: RealStamp = new RealStamp();
	selectedDate: Date;
  dateNow: IMyDate = this.getToday(0);
  dateTomorrow: IMyDate = this.getToday(1);
	stampData: StampData;
	absencecode: number;
	myComment: string;
	commentPh: string;

	getToday(inc: number): IMyDate {
		let date: Date = new Date();
		date.setDate(date.getDate() + inc);
		return { year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate() };
	}

	public myDatePickerOptions: IMyDpOptions = {
		dateFormat: 'dd.mm.yyyy',
		disableUntil: this.dateNow,
		width: "100%",
		showClearDateBtn: false,
		alignSelectorRight: false,
		showIncreaseDateBtn: true
	};

	public locale: string ;
  public model: any = { date: { year: this.dateTomorrow.year, month: this.dateTomorrow.month, day: this.dateTomorrow.day } };

	constructor(
		public dataservice: DataService,
		private translationService: AppTranslationService,
		private router: Router,
		public datepipe: DatePipe,
		private realtimestampService: RealTimeStampService,
		private authService: AuthService
	) {
    }

	ngOnInit() {
		if (this.dataservice.stampData == null || this.dataservice.realStamp == null)
			this.authService.logout();
		this.locale = this.dataservice.stampData.language;
		this.stampData = this.dataservice.stampData;
		this.realStamp = this.dataservice.realStamp;
		this.commentPh = this.translationService.getTranslation('helps.Comment');
	}
	

	public advanceBooking() {
		this.realStamp.direction = 2;

		var advDate = this.model.formatted;
		if (!advDate) {
			var d = this.model.date;
			this.realStamp.returndatetime = ("0" + d.day).slice(-2) + "/" + ("0" + d.month).slice(-2) + "/" + d.year;
		}
		else {
			this.realStamp.returndatetime = advDate.substring(0, 2) + "/" + advDate.substring(3, 5) + "/" +
				advDate.substring(6, 10);
		}
		this.realStamp.returndatetime += ' 00:00:00';
		this.realStamp.comment = this.myComment;
		this.realtimestampService.makeStamp(this.realStamp)
			.subscribe(data => {
				//if (data != null) {
				//	this.stampData = data;
				//}
				this.router.navigate(['/realtimestamp']);
			},
			(error: any) => {
          //console.log(error); 
			});
	}

	public goback() {
		this.router.navigate(['/realtimestamp']);
	}

	ngOnDestroy() {
		this.dataservice.stampData = this.stampData;
		this.dataservice.realStamp = this.realStamp; 
    }

}
