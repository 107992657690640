<div>
  <form class="form-horizontal" (ngSubmit)="save()" name="correctionForm" #f="ngForm">
    <div class="form-group">
      <label class="control-label col-sm-2" for="bookingTime">{{'stampcorrection.BookingTime' | translate}}</label>
      <div class="col-sm-10">
        <ngb-timepicker [(ngModel)]="clockTime" name="clockTime"> </ngb-timepicker>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="bookingid">{{'stampcorrection.Direction' | translate}}</label>
      <div class="col-sm-10">
        <select [(ngModel)]="selectedBookingId" name="bookingid" (change)="toBookingId()">
          <option *ngFor="let bid of bookingids" [selected]="bid.booking_id == stampEdit.bookingId" [ngValue]=bid>
            {{bid.descr}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="reasoncode">{{'stampcorrection.Reasoncode' | translate}}</label>
      <div class="col-sm-10">
        <select [(ngModel)]="selectedAbsenceCode" name="absencecode" (change)="toNumber()">
          <option value="0">{{'helps.NoAbsenceCode' | translate}}</option>
          <option *ngFor="let absencecode of absencecodes" [ngValue]=absencecode>
            {{absencecode.infotext}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="selectedWorkNumber && hasExtraData && worknumbers && rcodetype == 5">
      <label class="control-label col-sm-2" for="bookingid">{{'common.ExtraData' | translate}}</label>
      <div class="col-sm-10">
        <select [(ngModel)]="selectedWorkNumber" name="worknumber" (change)="toWorkNumber()">
          <option *ngFor="let worknumber of worknumbers" [ngValue]=worknumber [selected]="worknumber.tasknumber == selectedWorkNumber.tasknumber">
            {{worknumber.tasknumber}} {{worknumber.description}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="selectedCircAdd && hasExtraData && circadds && rcodetype == 6">
      <label class="control-label col-sm-2" for="bookingid">{{'common.ExtraData' | translate}}</label>
      <div class="col-sm-10">
        <select [(ngModel)]="selectedCircAdd" name="circadd" (change)="toCircAdd()">
          <option *ngFor="let circadd of circadds" [ngValue]=circadd [selected]="circadd.circ_add_id == selectedCircAdd.circ_add_id">
            {{circadd.circ_add_id}} {{circadd.description}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="hasExtraData && !(rcodetype == 5 || rcodetype == 6)">
      <label class="control-label col-sm-2" for="comment">{{'common.ExtraData' | translate}}</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" [(ngModel)]="stampEdit.extension_data" name="extdata">
      </div>
    </div>
    <div class="form-group" *ngIf="!hasExtraData">
      <label class="control-label col-sm-2" for="reasoncode2">{{'stampcorrection.Reasoncode2' | translate}}</label>
      <div class="col-sm-10">
        <select [(ngModel)]="selectedSecondCode" name="secondcode" (change)="toSecondNumber()">
          <option data-subtext="(Default)" value="0">{{'helps.NoAbsenceCode' | translate}}</option>
          <option *ngFor="let absencecode of absencecodes" [selected]="absencecode.reasoncode_id == stampEdit.secondReason"
                  [ngValue]=absencecode>
            {{absencecode.infotext}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="comment">{{'stampcorrection.Comment' | translate}}</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" [(ngModel)]="stampEdit.comment" name="comment">
      </div>
    </div>
    <div class="col-sm-7">
      <div class="pull-right">
        <button type="button" (click)="cancel()" class="btn btn-danger"><i class='fa fa-times'></i> {{'common.Cancel' | translate}}</button>
        <button type="submit" [disabled]="!f.form.valid" class="btn btn-primary"><i class='fa fa-save'></i>{{'common.Save' | translate}}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </form>
</div>
