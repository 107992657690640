<div class="parent">
  <div class="stamp-container center-block">
    <div class="form-group" *ngIf="jobtasks">
      <div class="form-group1">
        <p>{{'helps.JobTask' | translate}}</p>
        <select class="form-control" [(ngModel)]="selectedJobtask" (change)="toNumber()">
          <option disabled selected value=undefined></option>
          <option *ngFor="let jobtask of jobtasks" [selected]="jobtask.tasknumber == jobTsk.tasknumber" [ngValue]=jobtask>
            {{jobtask.tasknumber}}: {{jobtask.description}}
          </option>
        </select>
      </div>
    </div>
    <br>
    <div class="col-xs-12">
      <input type="text" class="form-control" placeholder={{commentPh}} [(ngModel)]="myComment" name="myComment">
    </div>
    <div class="footer">
      <div class="form-group">
        <p><button type="button" (click)="jobNumber()"  [disabled]="selectedJobtask == null" class="btn buttonJobnumber"> <strong>{{'controls.WithJobNumber' | translate}}</strong></button></p>
        <p><button type="button" (click)="endjobNumber()" [disabled]="jobTsk.tasknumber==''" class="btn buttonEndJobnumber"><strong>{{'controls.EndJobNumber' | translate}}</strong></button></p>
        <p><button type="button" (click)="goback()" class="btn buttonCancel"><strong>{{'common.Cancel' | translate}}</strong></button></p>
      </div>
    </div>
  </div>
</div>
