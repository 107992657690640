import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStoreManager } from './local-store-manager.service';
import { ReportParam } from '../models/reportparam.model';
import { StampCorrection } from '../models/stampcorrection.model';
import { StampCorrectionDayData } from '../models/stampcorrectiondaydata.model';
import { Observable } from 'rxjs';

@Injectable()
export class StampCorrectionService {
  private corrFetchUrl: string = 'api/stampcorrectionfetch';
  private corrFetchDayDataUrl: string = 'api/stampcorrectiondaydata';
  private corrDelUrl: string = 'api/stampcorrectiondelete';
  private corrAddUrl: string = 'api/stampcorrectionadd';
  private corrEditUrl: string = 'api/stampcorrectionedit';
  private corrAddRc: string = 'api/stampaddextracode';
  private corrChgDp: string = 'api/stampchangedayprogram';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getStampCorrectionDayData(params: ReportParam): Observable<StampCorrectionDayData> {
    //params.system = this.localStorage.getDataObject("sitename");
    //params.user = this.localStorage.getDataObject("username");
    return this.http.post<StampCorrectionDayData>(this.corrFetchDayDataUrl, params);
  }

  getStampCorrections(params: ReportParam): Observable<StampCorrection[]> {
    //params.system = this.localStorage.getDataObject("sitename");
    //params.user = this.localStorage.getDataObject("username");
    return this.http.post<StampCorrection[]>(this.corrFetchUrl, params);
  }

  deleteStamp(sc: StampCorrection, typ: string): Observable<StampCorrection[]> {
    //sc.language = this.localStorage.getDataObject("language");
    //sc.siteName = this.localStorage.getDataObject("sitename");
    //sc.user = this.localStorage.getDataObject("username");
    sc.sendmail = this.localStorage.getDataObject("sendmail");
    sc.operationType = typ;
    return this.http.post<StampCorrection[]>(this.corrDelUrl, sc);
  }

  newCorrectionStamp(sc: StampCorrection, typ: string): Observable<StampCorrection> {
    //sc.language = this.localStorage.getDataObject("language");
    //sc.siteName = this.localStorage.getDataObject("sitename");
    //sc.user = this.localStorage.getDataObject("username");
    sc.sendmail = this.localStorage.getDataObject("sendmail");
    sc.operationType = typ;
    return this.http.post<StampCorrection>(this.corrAddUrl, sc);
  }

  updateCorrectionStamp(sc: StampCorrection, typ: string): Observable<StampCorrection> {
    //sc.language = this.localStorage.getDataObject("language");
    //sc.siteName = this.localStorage.getDataObject("sitename");
    //sc.user = this.localStorage.getDataObject("username");
    sc.sendmail = this.localStorage.getDataObject("sendmail");
    sc.operationType = typ;
    return this.http.post<StampCorrection>(this.corrEditUrl, sc);
  }

  saveNewExtraCode(sc: StampCorrection): Observable<StampCorrection> {
    //sc.siteName = this.localStorage.getDataObject("sitename");
    //sc.user = this.localStorage.getDataObject("username");
    return this.http.post<StampCorrection>(this.corrAddRc, sc);
  }

  saveChangeDayProgram(sc: StampCorrection): Observable<StampCorrection> {
    //sc.siteName = this.localStorage.getDataObject("sitename");
    //sc.user = this.localStorage.getDataObject("username");
    return this.http.post<StampCorrection>(this.corrChgDp, sc);
  }

}
