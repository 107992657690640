import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CircAdds } from '../models/circadds.model';


@Injectable()
export class CircAddsService {
  private circaddsUrl: string = 'api/circadds';

  constructor(
    private http: HttpClient) { }

  getcircadds(): Observable<CircAdds[]> {
    return this.http.get<CircAdds[]>(this.circaddsUrl)
  }

}

