export class UserLogin {
  sitename: string;
  siteid: string;
  username: string;
  password: string;
  rememberMe: boolean;
  language: string;
  personName: string;
  rights: number;
  logintime: string;
/*  employee_id: string;*/
  guser: boolean;
  useJobnumber: boolean;
  useLunchstamp: boolean;
  enableCorrstamp: boolean;
  sendMail: boolean;
  showComment: number;
}
