import { StampData } from '../models/stampdata.model';
import { RealStamp } from '../models/realstamp.model';
import { Person } from '../models/person.model';
import { AbsenceCode } from '../models/absencecode.model';

export class DataService {
	public stampData: StampData;
	public realStamp: RealStamp;
  public onInit: boolean;
  public StampDate: Date;
  public PersonData: Person;
  public absencecodes: AbsenceCode[];
}
