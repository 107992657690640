export class RealStamp {
/*  employee_id: string;*/
  stamptime: string;
  direction: number;
  absencecode: number;
  secondcode: number;
  returndatetime: string;
  extdata: string;
  comment: string;
  jobnumberstamp: boolean;
  //language: string;
  //siteName: string;
  advanceBooking: boolean;
  coordstr: string;
}
