import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EsmiSite } from '../models/esmisite.model';
import { SetPassword } from '../models/setpassword.model';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable()
export class SiteService {
	private siteUrl: string = 'api/sites';
	constructor(
      private http: HttpClient,
      private localStorage: LocalStoreManager)
    { }

  getSystemData() {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.siteUrl}/${sitename}`;
    return this.http.get(endpointUrl, { responseType: 'text' })
      .pipe(
      )
	}

  getSites(): Observable<EsmiSite[]> {
    return this.http.get<EsmiSite[]>(this.siteUrl);
  }

  updatePassword(setPasswd: SetPassword) {
    setPasswd.sitename = this.localStorage.getDataObject("sitename");
    setPasswd.language = this.localStorage.getDataObject("language");
    return this.http.post(this.siteUrl, setPasswd, { responseType: 'text' })
      .pipe(
      )
  }

}
